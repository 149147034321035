import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SurveyFormBannerComponent } from 'app/presentation/survey-form-banner/survey-form-banner.component';
import { combineLatest, of, Subscription } from 'rxjs';
import { finalize, mergeMap, take } from 'rxjs/operators';
import { AppTracker } from 'src/app/components/tracking/app-tracker';
import { PreOrderStatuses } from 'src/app/core/domain/bulk-pre-order/pre-order-status.model';
import { BaseCategoryModel } from 'src/app/core/domain/commercial-categories.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetBulkPreOrderRequestsUseCase } from 'src/app/core/usecases/bulk-preorder/get-bulk-pre-order-requests.usecase';
import { GetCommercialCategoriesUseCase } from 'src/app/core/usecases/get-commercial-categories-usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetOnboardingFeatureUseCase } from 'src/app/core/usecases/get-onboarding-feature-usecase';
import { ListenToProductsPageExperienceChangesUseCase } from 'src/app/core/usecases/products/listen-to-products-page-experience-change.usecase';
import { ShouldDisplayNewHomePageUseCase } from 'src/app/core/usecases/products/should-display-new-home-page.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { SORT_BY_INTRODUCED_AT } from 'src/app/presentation/shared/constants/category-products';
import {
  ALL_PRODUCTS_CATEGORY,
  BULK_PREORDERABLE_CAROUSEL_NAME,
  FEATURE_FLAGS,
  LOYALTY_PROGRAM_FEATURE,
  PRE_ORDER_USER_FEATURE,
  TAMARA_PREFERENCE_KEY,
} from 'src/app/presentation/shared/constants/index';
import {
  DISCOUNTED_PRODUCTS_CAROUSEL,
  LOCKED_PRODUCTS_CAROUSEL,
  MERCHANT_LOCKED_PRODUCT_CAROUSEL,
  PRODUCTS_LANDING_PAGE_CAROUSELS,
  SALE_PRODUCTS_CAROUSEL,
  SUB_BANNERS_DATA,
  TAAGER_RECOMMENDATIONS_GROUP,
} from 'src/app/presentation/shared/constants/products-landing-page';
import {
  CarouselInterface,
  CategoryInterface,
  SubBannerDataInterface,
} from 'src/app/presentation/shared/interfaces/product.interafce';
import { CatalogService } from 'src/app/presentation/shared/services/catalog.service';
import { GetPreorderableProductsUseCase } from '../../../core/usecases/bulk-preorder/get-preorderable-products-use.case';
import { IsUserEligibleForOnboardingUseCase } from '../../../core/usecases/is-user-eligible-for-onboarding-usecase';
import { LastCategoryWelcomeCardComponent } from '../../last-category/last-category-welcome-card/last-category-welcome-card.component';
import { LastCategoryComponent } from '../../last-category/last-category.component';
import { OnboardingCurrentGoalProgressComponent } from '../../onboarding/components/onboarding-current-goal-progress/onboarding-current-goal-progress.component';
import { OnboardingGoalProgressCardComponent } from '../../onboarding/components/onboarding-goal-progress-card/onboarding-goal-progress-card.component';
import { OnboardingLearnWithUsComponent } from '../../onboarding/components/onboarding-learn-with-us/onboarding-learn-with-us.component';
import { OnboardingStoryGuideStateManager } from '../../onboarding/state-manager/onboarding-story-guide.state-manager';
import { QuestionnaireBannerComponent } from '../../shared/components/questionnaire-banner/questionnaire-banner.component';
import { TamaraIntegrationPopupComponent } from '../../shared/components/tamara-integration-popup/tamara-integration-popup.component';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { VIEW_PREORDERABLE_PRODUCTS_CAROUSEL } from '../../shared/constants/mixpanel';
import { BannerQuestionnaireService } from '../../shared/services/banner-questionnaire.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { ResponsiveService } from '../../shared/services/responsive.service';
import { SharedOverlayService } from '../../shared/services/shared-overlay.service';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { cesLocationNotifierUtility } from '../../shared/utilities/ces-location-notifier.utility';
import { featureAttributeAssign } from '../../shared/utilities/feature-attribute-assign.utility';
import { TreeNode } from '../../shared/utilities/tree.utility';
import { ProductCardComponent } from './product-card/product-card.component';
import { ProductsCarouselComponent } from './products-carousel/products-carousel.component';
import { SubBannerComponent } from './sub-banner/sub-banner.component';
import { TieringWidgetComponent } from './tiering-widget/tiering-widget.component';
import { TopBannerComponent } from './top-banner/top-banner.component';

@Component({
  selector: 'app-products-v2',
  templateUrl: './products-v2.component.html',
  styleUrls: ['./products-v2.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    OnboardingGoalProgressCardComponent,
    QuestionnaireBannerComponent,
    TopBannerComponent,
    OnboardingCurrentGoalProgressComponent,
    OnboardingLearnWithUsComponent,
    IvyCarouselModule,
    NgFor,
    ProductCardComponent,
    ProductsCarouselComponent,
    SubBannerComponent,
    TranslateModule,
    NgClass,
    LastCategoryWelcomeCardComponent,
    LastCategoryComponent,
    TieringWidgetComponent,
    SurveyFormBannerComponent,
  ],
})
export class ProductsV2Component implements OnInit, OnDestroy {
  public categories: CategoryInterface[] = [];

  public commercialCategories: TreeNode<BaseCategoryModel>[];

  public areIconsLoaded = false;

  public featuredProductsGroups: CarouselInterface[] = [];

  public itemsPerSlide: number;

  public showNavigationArrows: boolean;

  public arrowsOutside: boolean;

  public tooltipVisible: boolean;

  public carouselsData: {
    topGroup: CarouselInterface[];
    middleGroup: CarouselInterface[];
    bottomGroup: CarouselInterface[];
  } = { topGroup: [], middleGroup: [], bottomGroup: [] };

  public isCatalogDataLoaded = false;

  public subBannersData: SubBannerDataInterface[];

  public shouldShowSubBanners: boolean;

  public ksaOnboardingFlag = false;

  public shouldShowTamaraPreference = false;

  public isUserEligibleForQuestionnaire = false;

  private _lockedCarouselFlag = false;

  public isMerchantOnboardingEnabled = false;

  public preorderableProducts = [];

  public bulkPreorderableCarouselName = BULK_PREORDERABLE_CAROUSEL_NAME;

  public toggleCurrentGoalProgress: boolean;

  private _discountedAsSecondProductFlag = false;

  public isLTR: boolean;

  private _languageChangeSubscription: Subscription;

  private _cesLocationNotifierUtility = cesLocationNotifierUtility();

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  public shouldShowStockDistribution = false;

  public shouldDisplayDiscoverabilityFunnel = false;

  public isPreOrderEnabled = false;

  public isTieringSystemEnabled = false;

  public surveyBannerEnabled = false;

  constructor(
    private router: Router,
    private catalogService: CatalogService,
    private getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private bannerQuestionnaireService: BannerQuestionnaireService,
    private localStorageService: LocalStorageService,
    private _getPreorderableProducts: GetPreorderableProductsUseCase,
    private _getBulkPreOrderRequestsUseCase: GetBulkPreOrderRequestsUseCase,
    private _responsiveService: ResponsiveService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _isUserEligibleForOnboardingUseCase: IsUserEligibleForOnboardingUseCase,
    private _getOnboardingFeatureUseCase: GetOnboardingFeatureUseCase,
    private _onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager,
    private _sharedOverlayService: SharedOverlayService,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
    private _appTracker: AppTracker,
    private _getCommercialCategoriesUseCase: GetCommercialCategoriesUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    private _shouldDisplayNewHomePageUseCase: ShouldDisplayNewHomePageUseCase,
    private _listenToProductsPageExperienceChanges: ListenToProductsPageExperienceChangesUseCase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _matDialog: MatDialog,
  ) {
    const bannerShow = this.bannerQuestionnaireService.getDeclineBannerValue();
    bannerShow.subscribe({
      next: (bannerStatus: boolean) => {
        this.isUserEligibleForQuestionnaire = bannerStatus;
      },
    });
    this.toggleCurrentGoalProgress = false;
  }

  ngOnInit(): void {
    this._appTracker.sendEvent('product_v2_page_load');
    this.setupPreorderableProductsCrousel();
    this.setIsMerchantOnboardingEnabled();
    this.isStockDistributionEnabled();
    this.checkPreOrderEnabled();
    this.checkTieringSystemEnabled();
    const ksaOnboardingFlag$ = this.getFeatureFlagUseCase.execute(
      FEATURE_FLAGS.KSA_ONBOARDING_FLAG,
    );
    const bulkPreordersFlag$ = this.getFeatureFlagUseCase.execute(
      FEATURE_FLAGS.BULK_PRE_ORDERS_EXPERIMENT,
    );
    const lockedCarouselFlag$ = this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.LOCKED_CAROUSEL);
    const discountedAsSecondProductFlag$ = this.getFeatureFlagUseCase.execute(
      FEATURE_FLAGS.DISCOUNTED_AS_SECOND_PRODUCT,
    );
    combineLatest([
      ksaOnboardingFlag$,
      bulkPreordersFlag$,
      lockedCarouselFlag$,
      discountedAsSecondProductFlag$,
    ]).subscribe({
      next: ([ksaOnboardingFlag, lockedCarouselFlag, discountedAsSecondProductFlag]) => {
        this.ksaOnboardingFlag = ksaOnboardingFlag;
        this._lockedCarouselFlag = lockedCarouselFlag;
        this._discountedAsSecondProductFlag = discountedAsSecondProductFlag;
        this.setupCarrousselData();
        this.isLTR = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
        this.setUpFeaturedCommercialCategoriesCarousels();

        this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
          next: (event: LangChangeEvent) => {
            this.isLTR = event.lang === ENGLISH_LANGUAGE;
            this.setUpFeaturedCommercialCategoriesCarousels();
          },
        });

        this.getFeaturedGroups();
      },
    });

    this.getCatalogedProducts();
    this._cesLocationNotifierUtility({ action: 'write', location: 'products' });
    this.setDiscoverabilityFunnelStatuses();
    this.showTamaraPopup();
    this.isSurveyBannerEnabled();
  }

  public isStockDistributionEnabled(): void {
    this._getFeatureAttributeUseCase
      .execute(FEATURE_FLAGS.STOCK_DISTRIBUTION)
      .subscribe((attribute) => {
        this.shouldShowStockDistribution = featureAttributeAssign(attribute, user.id);
      });
  }

  public isSurveyBannerEnabled(): void {
    this._getFeatureAttributeUseCase
      .execute(FEATURE_FLAGS.SURVEY_BANNER_ENABLED)
      .subscribe((attribute) => {
        this.surveyBannerEnabled = featureAttributeAssign(attribute, user.id);
      });
  }

  public showTamaraPopup(): void {
    this._getFeatureAttributeUseCase.execute(FEATURE_FLAGS.TAMARA_PREFERENCE).subscribe({
      next: (attribute) => {
        this.shouldShowTamaraPreference = featureAttributeAssign(attribute, user.id);
        if (
          !this.localStorageService.getStorage(TAMARA_PREFERENCE_KEY) &&
          country.code === 'SAU' &&
          this.shouldShowTamaraPreference
        ) {
          this._matDialog.open(TamaraIntegrationPopupComponent, {
            hasBackdrop: false,
            position: this.isLTR ? { bottom: '5px', left: '0' } : { bottom: '5px', right: '0' },
            direction: this.isLTR ? 'ltr' : 'rtl',
            maxWidth: '536px',
            data: {
              isLTR: this.isLTR,
            },
          });
        }
      },
    });
  }

  public checkPreOrderEnabled(): void {
    this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.PREORDER_V3).subscribe({
      next: (flag) => {
        const selectedCountry = country.code;
        const userHasPreOrder = this._checkUserFeatureExistsUseCase.execute(
          PRE_ORDER_USER_FEATURE + selectedCountry.toLowerCase(),
        );
        this.isPreOrderEnabled = flag && userHasPreOrder;
        if (this.isPreOrderEnabled) {
          this.loadPreorderableProducts();
        }
      },
    });
  }

  public checkTieringSystemEnabled(): void {
    this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.LOYALTY_PROGRAM).subscribe({
      next: (flag) => {
        this.isTieringSystemEnabled =
          flag &&
          country.is('KSA') &&
          this._checkUserFeatureExistsUseCase.execute(LOYALTY_PROGRAM_FEATURE);
      },
    });
  }

  private setIsMerchantOnboardingEnabled(): void {
    this._getOnboardingFeatureUseCase
      .execute()
      .pipe(
        mergeMap((onboardingFeature) =>
          onboardingFeature ? this._isUserEligibleForOnboardingUseCase.execute() : of(false),
        ),
      )
      .subscribe({
        next: (isMerchantOnboardingEnabled) => {
          this.isMerchantOnboardingEnabled = isMerchantOnboardingEnabled;
          if (this.isMerchantOnboardingEnabled) {
            /**
             * We will actually protect the modal display behind the truthiness of
             * isMerchantOnboardingEnabled, because, a user may actually be using a different
             * account on the same machine, and so, they should not be seeing this.
             *
             * But if they use an account that is elligible for onboarding, then the story
             * which was present on the machine, will be continued from where it was left
             * at.
             */
            this._checkIfTheUserCanBeShownTheGetStartedModal();
          }
        },
      });
  }

  /**
   * For one reason or the other, the user may have reloaded the page
   * and hence they may not have seen the confirmation dialog to
   * 'Get Started' with the onboarding journey.
   */
  private _checkIfTheUserCanBeShownTheGetStartedModal(): void {
    const showModal =
      this._onboardingStoryGuideStateManager.showUserOptionToStartTheOnboardingJourney();
    if (showModal && window.innerWidth >= 992) {
      /**
       * Now, since we received the status to show modal as true, we can do so here, by
       * setting the overlay type in the shared overlay service.
       *
       * Notice this action, being an observable being emited to the Overlay Service will
       * not have any impact if the modal is already open
       */
      this._sharedOverlayService.setOverlayType('onboardingGettingStarted');
    }
  }

  public setupPreorderableProductsCrousel(): void {
    const screenSize = this._responsiveService.getScreenWidth();
    if (screenSize === 'xl') {
      this.itemsPerSlide = 4;
      this.showNavigationArrows = true;
      this.tooltipVisible = true;
      this.arrowsOutside = true;
    } else if (screenSize === 'lg') {
      this.itemsPerSlide = 3;
      this.tooltipVisible = true;
      this.showNavigationArrows = true;
      this.arrowsOutside = true;
    } else if (screenSize === 'md') {
      this.itemsPerSlide = 3;
      this.tooltipVisible = true;
      this.showNavigationArrows = false;
    } else if (screenSize === 'sm') {
      this.itemsPerSlide = 2;
      this.tooltipVisible = false;
      this.showNavigationArrows = false;
    } else {
      this.itemsPerSlide = 4;
      this.showNavigationArrows = true;
      this.tooltipVisible = true;
      this.arrowsOutside = true;
    }
  }

  setupCarrousselData(): void {
    this.carouselsData = {
      topGroup: [],
      middleGroup: [],
      bottomGroup: [PRODUCTS_LANDING_PAGE_CAROUSELS.ALL_PRODUCTS_CAROUSEL],
    };
  }

  setCategoryIcons(): void {
    this.categories.forEach((category) => {
      category.iconPath = `icon-category-icons-${category.name.replace(/ /gi, '-')}`;
    });
  }

  setUpFeaturedCategoriesCarousels(): void {
    this.setUpFeaturedCommercialCategoriesCarousels();
  }

  setUpFeaturedCommercialCategoriesCarousels(): void {
    this._getCommercialCategoriesUseCase
      .execute(country.code)
      .pipe(take(1))
      .subscribe({
        next: (tree) => {
          this.commercialCategories = tree.root.children;
          const featuredCategoriesCarousels = tree.root.children
            .filter((category) => category.value.featured)
            .map((category) => ({
              title: this.isLTR ? category.value.name.englishName : category.value.name.arabicName,
              categoryName: this.isLTR
                ? category.value.name.englishName
                : category.value.name.arabicName,
              sorting: SORT_BY_INTRODUCED_AT,
              categoryId: category.key,
            }));

          this.carouselsData.middleGroup = [featuredCategoriesCarousels[0]];
          this.carouselsData.bottomGroup = [
            ...this.carouselsData.bottomGroup.filter((carousel) => !carousel?.categoryId),
            ...featuredCategoriesCarousels.slice(1),
          ];

          this.setupCategoriesSubBanners();
        },
      });
  }

  goToCategories(): void {
    const queryString = {
      q: '',
      category: ALL_PRODUCTS_CATEGORY,
      currentPage: 1,
      items: 12,
      sorting: SORT_BY_INTRODUCED_AT,
    };
    this.router.navigate([this._appURLs.CATEGORY_PRODUCTS_URL], { queryParams: queryString });
  }

  setupCategoriesSubBanners(): void {
    const allCategoriesIds = this.commercialCategories.map((category) => category.key);
    this.subBannersData = SUB_BANNERS_DATA;
    this.subBannersData = this.subBannersData.map((subBanner) => {
      const id = this.commercialCategories.filter(
        (category) => category.value.name.arabicName === subBanner.categoryName,
      )[0]?.key;
      return { ...subBanner, categoryId: id };
    });
    this.shouldShowSubBanners = this.subBannersData.every((subBanner) =>
      allCategoriesIds.includes(subBanner.categoryId!),
    );
  }

  onRevertDesign(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'go_to_product_v1' });
  }

  getFeaturedGroups(): void {
    this.featuredProductsGroups = [
      ...(this.shouldShowStockDistribution ? [MERCHANT_LOCKED_PRODUCT_CAROUSEL] : []),
      ...(this._lockedCarouselFlag ? [LOCKED_PRODUCTS_CAROUSEL] : []),
      PRODUCTS_LANDING_PAGE_CAROUSELS.INTRODUCED_AT_CAROUSEL,
      TAAGER_RECOMMENDATIONS_GROUP,
      SALE_PRODUCTS_CAROUSEL,
      ...(this._discountedAsSecondProductFlag ? [DISCOUNTED_PRODUCTS_CAROUSEL] : []),
    ];
    this.setUpFeaturedProductsGroupsCarousels();
  }

  setUpFeaturedProductsGroupsCarousels(): void {
    this.carouselsData.topGroup.unshift(...this.featuredProductsGroups);
  }

  getCatalogedProducts(): void {
    this.catalogService
      .getCatalogedProducts()
      .pipe(
        finalize(() => {
          this.isCatalogDataLoaded = true;
        }),
      )
      .subscribe();
  }

  private loadPreorderableProducts(): void {
    this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.PRE_ORDER_V2).subscribe({
      next: (flag) => {
        if (flag) {
          this._getPreorderableProducts.execute().subscribe({
            next: ({ products }) => {
              this.preorderableProducts = products;
            },
            complete: () => {
              this._logMixpanelEventUseCase.execute({
                eventName: VIEW_PREORDERABLE_PRODUCTS_CAROUSEL,
              });
            },
          });
        } else {
          combineLatest({
            productsData: this._getPreorderableProducts.execute(),
            userPreorderRequests: this._getBulkPreOrderRequestsUseCase.execute(),
          }).subscribe({
            next: ({ productsData, userPreorderRequests }) => {
              this.preorderableProducts = productsData.products.map((variantGroup: any) => {
                this.localStorageService.setStorage('preorderRequests', userPreorderRequests);
                const currentProductInProgressPreorderRequests = userPreorderRequests.filter(
                  (request) =>
                    request.prodId === variantGroup.primaryVariant.prodID &&
                    request.status === PreOrderStatuses.INPROGRESS,
                );
                return {
                  ...variantGroup,
                  primaryVariant: {
                    ...variantGroup.primaryVariant,
                    isNotOrderable: currentProductInProgressPreorderRequests.length === 0,
                  },
                };
              });
            },
            complete: () => {
              this._logMixpanelEventUseCase.execute({
                eventName: VIEW_PREORDERABLE_PRODUCTS_CAROUSEL,
              });
            },
          });
        }
      },
    });
  }

  openPreorderableProducts(): void {
    const queryString = {
      q: '',
      category: '',
      preorderablProducts: true,
      currentPage: 1,
      items: 12,
      sorting: SORT_BY_INTRODUCED_AT,
    };
    this.router.navigate([this._appURLs.CATEGORY_PRODUCTS_URL], { queryParams: queryString });
  }

  /**
   * once we no longer need to show the current goal progress, we can
   * remove it from the ui
   */
  toggleCurrentGoalProgressCard(status: boolean): void {
    this.toggleCurrentGoalProgress = status;
    this._changeDetectorRef.detectChanges();
  }

  setDiscoverabilityFunnelStatuses(): void {
    this.shouldDisplayDiscoverabilityFunnel = this._shouldDisplayNewHomePageUseCase.execute();
    this._listenToProductsPageExperienceChanges.execute().subscribe(() => {
      this.shouldDisplayDiscoverabilityFunnel = this._shouldDisplayNewHomePageUseCase.execute();
    });
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
    this._cesLocationNotifierUtility({ action: 'write', location: ' ' });
  }
}

<div
  class="products-carousel"
  [ngClass]="{
    'locked-products':
      title === 'PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_PRODUCTS_CAROUSEL_NAME' &&
      shouldShowStockDistribution
  }"
  *ngIf="!loading && (products?.length || variantGroups?.length)"
  data-test-id="products-carousel"
>
  <div class="d-flex flex-wrap justify-content-between category-header-container">
    <div class="category-section-header">
      <img
        class="on-sale-carousel-img"
        *ngIf="onlyGroupsOnSale"
        [src]="assetsPath + 'img/percentage_icon.svg'"
      />
      <img
        class="discount-carousel-img"
        *ngIf="onlyDiscountedAsSecondInPlacement"
        [src]="assetsPath + 'iconfont-input/discount-lightning.svg'"
      />
      <div
        [ngClass]="{
          'custom-carousel-title': onlyGroupsOnSale || onlyDiscountedAsSecondInPlacement,
          'sale-carousel-title': onlyGroupsOnSale,
          'discount-carousel-title': onlyDiscountedAsSecondInPlacement,
          'locked-carousel-title':
            shouldShowStockDistribution &&
            carouselData.title === 'PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_PRODUCTS_CAROUSEL_NAME'
        }"
      >
        <ng-container
          *ngIf="
            shouldShowStockDistribution &&
              carouselData.title === 'PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_PRODUCTS_CAROUSEL_NAME';
            else standardTitle
          "
        >
          <div class="row">
            <div class="col-2">
              <img [src]="assetsPath + 'img/big-lock.svg'" />
            </div>
            <div class="col-10">
              <p class="category-section-header__text heading2--bold">
                {{ title | translate | titlecase }}
              </p>
              <p class="caption1--regular">
                {{
                  'PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_PRODUCTS_SUBTITLE' | translate | titlecase
                }}
              </p>
            </div>
          </div>
        </ng-container>
        <ng-template #standardTitle>
          <p class="category-section-header__text heading2--bold">
            {{ title | translate | titlecase }}
          </p>
        </ng-template>
      </div>
    </div>
    <div class="view-all body1--bold" *ngIf="!upSellableVariant" (click)="goToLink()">
      {{ 'HEADER.CATEGORIES_MEGA_MENU.VIEW_ALL' | translate }}
    </div>
  </div>
  <div
    class="d-flex"
    [ngClass]="{
      'manual-width': title === 'PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_PRODUCTS_CAROUSEL_NAME'
    }"
  >
    <carousel
      [ngClass]="{ 'ksa-card': shouldShowQuantityDiscount && shouldShowQuantityDiscountCard }"
      [cellsToShow]="itemsPerSlide"
      [overflowCellsLimit]="10"
      [arrowsOutside]="arrowsOutside"
      [arrows]="showNavigationArrows"
    >
      <div class="carousel-cell" *ngFor="let variantGroup of variantGroups">
        <app-product-card
          *ngIf="isCatalogDataLoaded"
          [variantGroup]="variantGroup"
          [tooltipVisible]="tooltipVisible"
          [isFromLockedCarousel]="lockedOnly"
          [shouldShowQuantityDiscountCard]="
            shouldShowQuantityDiscount && shouldShowQuantityDiscountCard
          "
          [shouldShowStockDistribution]="
            shouldShowStockDistribution &&
            title === 'PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_PRODUCTS_CAROUSEL_NAME'
          "
        ></app-product-card>
      </div>
      <div class="carousel-cell" *ngFor="let product of products">
        <app-product-card
          *ngIf="isCatalogDataLoaded"
          [product]="product"
          [tooltipVisible]="tooltipVisible"
          [isFeaturedProduct]="product.isFeaturedProduct"
          [isUpsellableProduct]="!!upSellableVariant"
          [shouldShowStockDistribution]="
            shouldShowStockDistribution &&
            title === 'PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_PRODUCTS_CAROUSEL_NAME'
          "
        ></app-product-card>
      </div>
    </carousel>
  </div>
</div>
